@font-face {
  font-family: "Lubalin Graph Std Demi";
  src: url("assets/fonts/LubalinGraphStd-Demi.otf");
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.status-button div {
  width: 65px;
  margin: auto;
}

.grecaptcha-badge {
  visibility: hidden;
}

.hideScrollbar::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ddd;
}

/* Handle */
*::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background-color: #bbb;
}

/* /////////////////// TABLE CSS ///////////////////////// */
.rdt_Table {
  background: #fff !important;
  overflow: hidden;
}
.rdt_TableHeadRow {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  min-height: 60px;
  padding: 12px 20px 12px 16px;
  background-color: #fff !important;
}
.rdt_TableCol > div > div {
  white-space: normal !important;
}
.rdt_TableRow {
  white-space: normal !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 17px;
  min-height: 60px !important;
  padding: 12px 14px 12px 16px;
  background-color: #fff !important;
  border-bottom: 1px solid #eee !important;
}
.rdt_TableCell > div {
  white-space: normal !important;
}
/* width */
.tableBox > div::-webkit-scrollbar {
  height: 8px;
}

/* Track */
.tableBox > div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.tableBox > div::-webkit-scrollbar-thumb {
  background: #cfcfcf;
  border-radius: 0px;
}

/* Handle on hover */
.tableBox > div::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}
/* Hide the default browser date input icon */
.custom-date-input::-webkit-calendar-picker-indicator {
  background: transparent;
}

/* Define your custom icon using the ::before pseudo-element */
.custom-date-input::before {
  content: url("../public/Images/Calendar.png"); /* Replace with the path to your custom icon */
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust this value to position your custom icon */
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the icon doesn't interfere with input interaction */
}

.radio_button .chakra-radio__label {
  font-size: 14px;
  font-weight: 600;
  color: #222;
}
.radio_button .chakra-radio__control[data-checked] {
  background: #006f80;
  border-color: #006f80;
  color: #b1e4e3;
}

/* Styles for screens wider than 992px */
@media screen and (min-width: 992px) {
  .popover_drop .chakra-popover__popper {
    transform: translate(
      40px,
      -237px
    ) !important; /* Your styles for screens wider than 992px here */
  }
}

.margin_top .chakra-form-control {
  margin-top: 12px;
}

.menu-history-wrapper svg {
  border-left: 0;
}

.menu-history-wrapper .chakra-menu__menu-list {
  padding: 10px 0;
  border: 0;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
}

.menu-history-wrapper .chakra-menu__menu-list button {
  font-size: 14px;
  font-weight: 500;
  height: initial;
  line-height: 22px;
  padding: 3px 16px;
}

.history-fees-wrap.menu-history-wrapper > div {
  width: 190px !important;
}

.history-wrapper-menu.menu-history-wrapper > div {
  width: 200px !important;
}

.cancel-reserv-wrapper.menu-history-wrapper > div {
  width: 278px !important;
}
.cancel-refund-wrapper.menu-history-wrapper > div {
  width: 232px !important;
}
.with-insurance-wrapper.menu-history-wrapper > div {
  width: 335px !important;
}

@media screen and (max-width: 380px) {
  .with-insurance-wrapper.menu-history-wrapper > div {
    width: 275px !important;
  }
}
/* react date picker style */
.react-datepicker-wrapper input {
  border-radius: 6px;
  border: 1px solid #eee;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 500;
  color: #222;
  width: 100px;
}

.react-datepicker-wrapper input.check-in-date-wrapper {
  border: 0;
}

.react-datepicker-wrapper input:focus-visible {
  outline: none;
}

.invoice-form-wrapper input:-webkit-autofill,
.invoice-form-wrapper input:-webkit-autofill:hover,
.invoice-form-wrapper input:-webkit-autofill:focus,
.invoice-form-wrapper input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
